// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-ausdict-js": () => import("./../../../src/pages/about-ausdict.js" /* webpackChunkName: "component---src-pages-about-ausdict-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-me-js": () => import("./../../../src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-by-topic-js": () => import("./../../../src/pages/by-topic.js" /* webpackChunkName: "component---src-pages-by-topic-js" */),
  "component---src-pages-entry-index-js": () => import("./../../../src/pages/entry-index.js" /* webpackChunkName: "component---src-pages-entry-index-js" */),
  "component---src-pages-help-js": () => import("./../../../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-part-of-speech-js": () => import("./../../../src/pages/part-of-speech.js" /* webpackChunkName: "component---src-pages-part-of-speech-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-suggest-js": () => import("./../../../src/pages/suggest.js" /* webpackChunkName: "component---src-pages-suggest-js" */),
  "component---src-templates-chapter-page-js": () => import("./../../../src/templates/chapter-page.js" /* webpackChunkName: "component---src-templates-chapter-page-js" */),
  "component---src-templates-entry-detail-js": () => import("./../../../src/templates/entry-detail.js" /* webpackChunkName: "component---src-templates-entry-detail-js" */),
  "component---src-templates-part-of-speech-page-js": () => import("./../../../src/templates/part-of-speech-page.js" /* webpackChunkName: "component---src-templates-part-of-speech-page-js" */)
}

