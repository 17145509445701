// extracted by mini-css-extract-plugin
export var container = "layout-module--container--YM7Lx";
export var navbar = "layout-module--navbar--2JXTD";
export var heading = "layout-module--heading--2By-2";
export var siteTitle = "layout-module--siteTitle--3H0UB";
export var content = "layout-module--content--3GWBe";
export var navLinks = "layout-module--nav-links--1VlO2";
export var navLinkItem = "layout-module--nav-link-item--31U8T";
export var navLinkText = "layout-module--nav-link-text--ExV28";
export var explication = "layout-module--explication--2V2he";
export var line = "layout-module--line--y8Oxv";
export var noteClass = "layout-module--noteClass--3J_ev";
export var example = "layout-module--example--1EaSM";
export var real = "layout-module--real--2A74w";
export var hansard = "layout-module--hansard--32p0L";
export var constructed = "layout-module--constructed--6tivs";
export var scenario = "layout-module--scenario--14Ml-";
export var situation = "layout-module--situation--Ik6p9";
export var exampleText = "layout-module--exampleText--1Y4jN";
export var relValue = "layout-module--relValue--1yjQl";
export var relWord = "layout-module--relWord--G3pRO";
export var relPhrase = "layout-module--relPhrase--Huk6d";
export var exampleRef = "layout-module--exampleRef--12Km3";
export var contextClass = "layout-module--contextClass--1pBl3";
export var goBack = "layout-module--goBack--13pLe";
export var small = "layout-module--small--eiccT";
export var formClass = "layout-module--formClass--2FJSw";
export var inputClass = "layout-module--inputClass--1-OeX";
export var button = "layout-module--button--3LQZs";
export var searchIcon = "layout-module--searchIcon--UrJ_K";
export var stelist = "layout-module--stelist--twtQS";
export var paragraph = "layout-module--paragraph--1OWKs";
export var footer = "layout-module--footer--3iD_9";
export var footerLeft = "layout-module--footerLeft--iH-n1";
export var footerRight = "layout-module--footerRight--1iNmJ";
export var illustrative = "layout-module--illustrative--1vRDD";
export var illustration = "layout-module--illustration--1BHG1";
export var icon = "layout-module--icon--2wix1";
export var menuIcon = "layout-module--menuIcon--1Irff";
export var homeIcon = "layout-module--homeIcon--19iKI";
export var homePage = "layout-module--homePage--2PJVi";
export var homeContainer = "layout-module--homeContainer--3jqi3";
export var chapterContainer = "layout-module--chapterContainer--28Pgv";
export var chapterLink = "layout-module--chapterLink--1sdhS";
export var menuPageHeading = "layout-module--menu-page-heading--1O64H";
export var menuPageLink = "layout-module--menu-page-link--1PxsO";
export var listLink = "layout-module--list-link--2X337";
export var menuContainer = "layout-module--menu-container--2BXGy";
export var menuLink = "layout-module--menu-link--1p7a6";
export var menuText = "layout-module--menu-text--361QO";
export var mobileMenuContainer = "layout-module--mobile-menu-container--2LEp_";
export var mobileMenuIcon = "layout-module--mobile-menu-icon--1VQRn";
export var mobileDropbtn = "layout-module--mobile-dropbtn--1NsUd";
export var mobileDropdownLeft = "layout-module--mobile-dropdown-left--3xs0v";
export var mobileDropdownContent = "layout-module--mobile-dropdown-content--3FuvJ";
export var dropbtn = "layout-module--dropbtn--pj-3M";
export var dropdown = "layout-module--dropdown--25KdX";
export var dropdownContent = "layout-module--dropdown-content--F6ADB";
export var dropdownMenu = "layout-module--dropdown-menu--1SBpb";
export var inputSuggestion = "layout-module--inputSuggestion--1agNq";
export var textSuggestion = "layout-module--textSuggestion--2P7Of";
export var suggestionForm = "layout-module--suggestionForm--219pJ";
export var suggestionButton = "layout-module--suggestionButton--2tmHw";
export var formLabel = "layout-module--formLabel--1wNUl";