import { relValue, relWord, relPhrase, real, constructed, hansard, scenario, situation } from "../../../../src/components/layout.module.css";
import * as React from 'react';
export default {
  relValue,
  relWord,
  relPhrase,
  real,
  constructed,
  hansard,
  scenario,
  situation,
  React
};